<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <h3>{{ operationName }} Currency</h3>
          <CAlert :color="alertType" v-if="message"> {{message}} </CAlert>
          <CSelect
            label="Currency"
            :value.sync="currency.currencyId"
            :plain="true"
            :options="currencies"
          >
          </CSelect>

          <CButton color="secondary" @click="goBack" style="margin-right: 15px"
            ><CIcon name="cil-list"/> Back to the list</CButton
          >

          <CButton color="primary" @click="create()"><CIcon name="cil-save"/> Save</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
export default {
  name: "AddNewCurrency",
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      token: localStorage.getItem("api_token"),

      currencies: [],
      currency: {
        currencyId: null,
      },
      // Alert
      message: null,
      alertType: "danger",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    create() {
      let self = this;
      axios
        .post(
          `${this.$apiAdress}/v1/PartnerCurrency`,
          self.currency
        )
        .then(function () {
          self.goBack();
        })
        .catch(function (error) {
          self.message = error;
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
        });
    },
    getCurrencies() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/Currencies`)
        .then(function (response) {
          self.currencies = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getCurrencies();
  },
};
</script>
